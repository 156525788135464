import { css } from "@emotion/core";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Logo } from "./Logo";
import ReactMarkdown from 'react-markdown'

const containerCSS = css`
margin-top: 72px;
margin-bottom: 32px;
position: relative;

display: flex;
flex-direction: column;
align-items: center;
text-align: center;

p {
    max-width: 800px;
}

h2 {
    font-size: 2em;
    font-weight: bold;
    margin-top: 16px;
}

img {
    width: 100%;
    max-width: 800px;
    border-radius: 16px;
}

.demo-paragraph p {
    margin: auto;
}
`;

const goalContainerCSS = css`
    margin-bottom: 8px;
    border: 2px solid #e0cfaa;
    border-radius: 16px;
    padding: 8px;
    display: flex;
    align-items: center;

    @media (max-width: 824px) {
        flex-direction: column;
    }

    p {
        text-align: left;
        margin: 0px;
    }
`;


const rewardsContainerCSS = css`
    margin-bottom: 8px;
    border: 2px solid #e0cfaa;
    border-radius: 16px;
    padding: 8px;
    p {
        text-align: left;
        margin: 0px;
    }
`;

const rightArrowCSS = css`
    width: 0; 
    height: 0; 
    position: absolute;
    left: 0;
    top: .5em;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-left: .4em solid orange;
`

const leftArrowCSS = css`
    width: 0; 
    height: 0; 
    position: absolute;
    right: 0;
    top: .5em;
    border-top: .4em solid transparent;
    border-bottom: .4em solid transparent;
    border-right: .4em solid orange;
`

type Data = {
    imageURL: string
    title: string
    goalsTitle: string
    paragraph01: string
    paragraph02: string
    rewards: RewardStatus[]
    stats: GoalStatus[]
} | undefined

type RewardStatus = { title: string, requestedFires: number, unlockedText: string }
type GoalStatus = { description: string, goals: number[], value: number, title: string, additiveMode: boolean }

export const DemoFestival: React.FC<{}> = () => {

    const [data, setData] = useState<Data>(undefined)

    useEffect(() => {
        async function fetchData() {
            const result = await axios(
                'https://roots-of-pacha.firebaseio.com/demo-festival.json',
            )
            setData(result.data)
        }
        fetchData()
    }, [])

    var goalsAchieved = 0
    if (data) {
        goalsAchieved = data.stats.reduce((v, s) => v + s.goals.reduce((v, x) => v + (x > -1 && (x <= s.value) ? 1 : 0), 0), 0);
    }

    return <div css={containerCSS}>
        {
            data && <React.Fragment>
                <a href="/">
                    <Logo style={{ maxWidth: 312, marginTop: 16, marginBottom: 16 }} />
                </a>
                <ReactMarkdown>{data.title}</ReactMarkdown>
                {data.paragraph01 && <ReactMarkdown className="demo-paragraph">{parseMarkdownText(data.paragraph01)}</ReactMarkdown>}
                <ReactMarkdown>{data.goalsTitle}</ReactMarkdown>
                <div style={{ width: "100%", maxWidth: 800, margin: "0px 16px 16px" }}>
                    {
                        data.stats.map((x, i) => {
                            return <GoalRenderer key={i} stats={x} />
                        })
                    }
                </div>
                <div css={goalContainerCSS} style={{ width: "100%", maxWidth: 800, margin: "0px 16px 16px" }}>
                    <strong style={{ fontSize: "1.5em" }}>Total fires lit: 🔥 x {goalsAchieved}</strong>
                </div>
                <div css={rewardsContainerCSS} style={{ width: "100%", maxWidth: 800, margin: "0 16px 16px" }}>
                    <div><strong style={{ fontSize: "1.25em" }}>Rewards</strong></div>
                    {data.rewards.map((x, i) => {
                        let unlocked = goalsAchieved >= x.requestedFires;
                        return <div key={i} style={{ display: "flex", margin: "4px 0px" }}>{unlocked ? "🎉" : "🔒"}&nbsp;<ReactMarkdown>{x.title}</ReactMarkdown>:&nbsp;{ unlocked ? <ReactMarkdown>{x.unlockedText}</ReactMarkdown> : `[${x.requestedFires - goalsAchieved} more 🔥 to unlock]` }</div>;
                    })}
                </div>
                <img src={data.imageURL} style={{ marginBottom: 16 }} />
                {data.paragraph02 && <ReactMarkdown className="demo-paragraph">{parseMarkdownText(data.paragraph02)}</ReactMarkdown>}
            </React.Fragment>
        }
    </div>;
}

const GoalRenderer: React.FC<{ stats: GoalStatus }> = ({ stats }) => {

    var currentGoal = stats.goals.sort(x => x > -1 ? x : 9999999).find(x => x > -1 && x > stats.value);
    return <div css={goalContainerCSS}>
        <div style={{ display: "flex", alignItems: "center" }}>
            <ReactMarkdown>{stats.title}</ReactMarkdown>
            :
            {
                stats.goals.map((x, i) => {
                    var current = currentGoal == x;
                    var achieved = x > -1 && x <= stats.value;
                    return <div key={i} style={{
                        fontWeight: (current || achieved) ? "bold" : "normal",
                        textDecoration: achieved ? "line-through" : "none",
                        position: "relative",
                        margin: i == 0 ? "0px 8px" : "0px 8px 0px 0px",
                        padding: "2px 8px",
                        backgroundColor: achieved ? "orange" : "none",
                        color: achieved ? "white" : "inherit",
                        border: achieved ? "2px solid orange" : current ? "2px solid orange" : "2px solid gray",
                        borderRadius: 8
                    }}>
                        {achieved ? "✔" : ""}
                        {current ? "🔥" : ""}
                        {current && <div css={rightArrowCSS}></div>}
                        {x > -1 ? `${stats.additiveMode ? "+" : ""}${x > 1000 ? `${(x / 1000)}K` : x}` : "???"}
                        {current && <div css={leftArrowCSS}></div>}
                    </div>;
                })
            }
        </div>
        <ReactMarkdown>{stats.description}</ReactMarkdown>
    </div>
}

const parseMarkdownText = (input: string): string => {
    return input.replace(/\<br\/\>/g, `&nbsp;  \n`)
}

export default DemoFestival;