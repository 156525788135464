import { SerializedStyles } from "@emotion/utils"
import { graphql, StaticQuery } from "gatsby"
import React from "react"

type Props = {
    css?: SerializedStyles
    style?: React.CSSProperties
}

export const Logo: React.FC<Props> = ({ style, css }) => {
    return <StaticQuery
        query={graphql`
      query {
        logo: file(relativePath: { eq: "img/logo.png" }) {
          publicURL
        }
      }
  `}
        render={data => {
            return <img style={style} css={css} draggable={false} src={data.logo.publicURL} />
        }} />
}