import "../styles/mui.scss";

import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import { outer, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';

import Footer from '../components/Footer';
import "react-image-gallery/styles/scss/image-gallery.scss";

import { useEffect, useRef } from 'react';
import { SiteHelmet } from "../components/Helmet";

import { InfoSection } from "../components/InfoSection";

import VideoHeader from "../components/VideoHeader";
import FixedBar from "../components/FixedBar";
import { GameDescription } from "../components/GameDescription";
import { scroller } from "react-scroll";
import DemoFestival from "../components/DemoFestival";

const WrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PageTemplate = css`
  .site-main {
    width: 100%;
  }
`;

const DemoFestivalLanding: React.FC<any> = () => {
  return <IndexLayout>
    <SiteHelmet />
    <Wrapper css={[PageTemplate, WrapperCss]}>
      <FixedBar />
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <DemoFestival />
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout >
}

export default DemoFestivalLanding;
